<template>

  <DefaultCrudView
    @create-new="newActionset"
    :breadcrumbsItems="breadcrumbs"
    :actions="crudActions"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="actionsets"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions_key="{ item }">
          <span
            v-for="(key, index) in item.actions_key"
            :key="key"
            color="primary"
            dark
          >
            {{ key }}<span v-if="index != item.actions_key.length - 1">, </span> 
          </span>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editActionset(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editActionsetFilter(item)"
              >
                mdi-filter-plus
              </v-icon>
            </template>
            <span>{{ $t('edit_filter') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <FilterAssocModal 
        @submit-filter="saveActionsetFilter"
        ref="filter_assoc_modal"
      />

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import FilterAssocModal from '@/components/node/FilterAssocModal'

import ActionActions from '@/store/operation/action/actions-types'
import ActionsetActions from '@/store/operation/actionset/actions-types'
import ActionsetMutations from '@/store/operation/actionset/mutations-types'
import AppActions from '@/store/app/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'

export default {
  name: "Actionset",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
    FilterAssocModal
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem'
    ]),

    ...mapState({
      fields: state => state.operation.actionset.fields,
      activesets: state => state.operation.actionset.actionsets,
      headers: state => state.operation.actionset.tableHeaders,
      selectedActionset: state => state.operation.actionset.selectedActionset
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('actionsets'),
          prependIcon: this.getIconForOperationSettingsItem('actionsets'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      actionsets: [],
      options: {
        sortBy: ['aspect_key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      crudActions: [
        {
          text: "create_actionset",
          eventToEmit: "create-new",
        }
      ],
      tenants: [],
      actions: []
    }
  },

  mounted() {
    this.[ActionsetMutations.SET_SELECTED_ACTIONSET](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[ActionsetMutations.SET_FIELD_TENANTS](tenants.items)
        this.tenants = tenants.items
      })

    this.[ActionActions.GET_ALL_ACTIONS]()
      .then((actions) => {
        this.[ActionsetMutations.SET_FIELD_ACTIONS](actions.items)
        this.actions = actions.items
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('operation/action', [
      ActionActions.GET_ALL_ACTIONS
    ]),

    ...mapActions('operation/actionset', [
      ActionsetActions.GET_ALL_ACTIONSETS,
      ActionsetActions.SAVE_ACTIONSET,
      ActionsetActions.UPDATE_ACTIONSET,
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('operation/actionset', [
      ActionsetMutations.SET_FIELD_ACTIONSETS,
      ActionsetMutations.SET_SELECTED_ACTIONSET,
      ActionsetMutations.SET_FIELD_TENANTS,
      ActionsetMutations.SET_FIELD_ACTIONS
    ]),

    newActionset() {
      this.[ActionsetMutations.SET_SELECTED_ACTIONSET](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[ActionsetActions.SAVE_ACTIONSET]()
        .then(() => {
          let message = this.selectedActionset ? 'Actionset edited' : 'Actionset created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)
      this.loading = true
      this.[ActionsetActions.GET_ALL_ACTIONSETS](options)
        .then((result) => {
          this.actionsets = result.items
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editActionset(actionset) {
      let actionsIds = []

      if (actionset.actions_key) {
        actionset.actions_key.forEach((actionKey) => {
          let action = this.actions.find(action => {return action.action_key == actionKey})
          actionsIds.push(action.action_id)
        })
      }

      actionset['actions_ids'] = actionsIds

      this[ActionsetMutations.SET_SELECTED_ACTIONSET](actionset)
      this.$refs.modal.openModal()
    },

    editActionsetFilter(actionset) {
      this[ActionsetMutations.SET_SELECTED_ACTIONSET](actionset)

      this.$refs.filter_assoc_modal.tenantIdFilter = actionset.tenant_id

      this.$refs.filter_assoc_modal.open(actionset.filter_id)
    },

    saveActionsetFilter(form) {
      const params = {
        filter_id: parseInt(form.get('filter')) || null
      }

      this[ActionsetActions.UPDATE_ACTIONSET](params)
        .then(() => {
          this.$refs.filter_assoc_modal.loading = false

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))

          this.$refs.filter_assoc_modal.close()

          this.reloadData()
        })
        .catch(() => this.$refs.filter_assoc_modal.loading = false)
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getModalTitle() {
      return this.selectedActionset ? this.$t('edit_actionset') : this.$t('new_actionset')
    }
  }
}

</script>